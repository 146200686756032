/**
* Bootstrap 4 Options
 */
$enable-gradients: true;
$enable-shadows: true;

//////////////////////////

.navbar-laravel {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

.status-cell--pending {
  background-color: yellow;
}

.status-cell--paid {
  background-color: green;
}

.jsgrid-row > .jsgrid-cell.status-cell--pending,
.jsgrid-alt-row > .jsgrid-cell.status-cell--pending {
  @extend .status-cell--pending;
}

.jsgrid-row > .jsgrid-cell.status-cell--paid,
.jsgrid-alt-row > .jsgrid-cell.status-cell--paid {
  @extend .status-cell--paid;
}

.strong {
  font-weight: bold !important;
}

.u-label-required {
  position: relative;

  &:after {
    color: $red;
    content: '*';
    font-size: 1.75rem;
    position: absolute;
    top: -5px;
    right: -10px;
  }
}

.c-fab {
  height: auto;
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: -.9rem;
  left: -1rem;

  a {
    display: inline-block;
    padding: 1rem;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;

    &:hover {
      text-decoration: none;
    }
  }
  h4 {
    margin-bottom: 0;
    color: white;
    position: relative;
    top: 1px;
  }
}

table.table {
    font-family: 'Helvetica', sans-serif;
}

table.table.table-hover tbody tr {
  cursor: pointer;
}

.c-search-box {
  display: flex;

  .c-search-box-container {
    flex-grow: 1;
    flex-shrink: 1;
  }

  .c-search-box--button {
    cursor: pointer;
    border: none !important;
    background-color: unset !important;
    height: 2.8rem;
    position: relative;
    left: -2rem;
    z-index: 4;
  }
}

.l-footer {
  background-color: #ffffff;
  box-shadow: 0 -2px 4px rgba(0,0,0,.04);
  display: inline-block;
  margin-top: 7rem !important;

  @media (min-width: 768px) {
    margin-top: 3rem !important;
  }
}
