
// Fonts
@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,600");

// Variables
@import 'variables';

// Custom Bootstrap
@import 'custom';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Open-Iconic
@import "~open-iconic/font/css/open-iconic-bootstrap";

// JQuery-ui
@import "~jsgrid/dist/jsgrid.min.css";
@import "~jsgrid/dist/jsgrid-theme.min.css";
@import '~jquery-ui/themes/base/all.css';

.navbar-laravel {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}
